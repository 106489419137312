import React from 'react';
import styled from 'styled-components';
import { QuarteryTitle } from "../../UI/Titles.jsx";
import { white } from "../../UI/Variables.js";

const Block = styled.ul`
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    align-self: stretch;
    @media screen and (min-width: 768px) {
        flex-direction: row;
    }
`;

const List = styled.li`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    align-self: stretch;
    @media screen and (min-width: 768px) {
        width: 50%;
        flex: 1 0 0;
    }
    @media screen and (min-width: 1024px) {
        width: 25%;
    }
`;

const Item = styled.div`
    background: ${white};
    display: flex;
    height: 7.375rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    align-self: stretch;
    @media screen and (min-width: 1024px) {
        height: 17.875rem;
    }
`;

const Image = styled.div`
    width: 6.125rem;
    height: 6.125rem;
    background: ${({ src }) => `url(${src}) lightgray 50% / cover no-repeat`};
    @media screen and (min-width: 768px) {
        width: 9.125rem;
        height: 9.125rem;
    }
    @media screen and (min-width: 1024px) {
        width: 12rem;
        height: 12rem;
    }
`;

const ListBlock = () => {
    return (
        <Block>
            <List>
                <Item>
                    <Image src="https://i.imgur.com/qzBZqM0.jpg" alt="Administración de redes sociales" title="Administración de redes sociales" />
                </Item>
                <QuarteryTitle>Administración de redes sociales</QuarteryTitle>
            </List>

            <List>
                <Item>
                    <Image src="https://i.imgur.com/cSWCTKs.jpg" alt="Diseño de piezas publicitarias" title="Diseño de piezas publicitarias" />
                </Item>
                <QuarteryTitle>Diseño de piezas publicitarias</QuarteryTitle>
            </List>

            <List>
                <Item>
                    <Image src="https://i.imgur.com/XzMvNYs.png" alt="Creación de sitios Web" title="Creación de sitios Web" />
                </Item>
                <QuarteryTitle>Creación de sitios Web</QuarteryTitle>
            </List>

            <List>
                <Item>
                    <Image src="https://i.imgur.com/gNYWsyQ.png" alt="Administración de Ecommerce" title="Administración de Ecommerce" />
                </Item>
                <QuarteryTitle>Administración de Ecommerce</QuarteryTitle>
            </List>

            <List>
                <Item>
                    <Image src="https://i.imgur.com/cB3rFok.jpg" alt="Administración de blogs" title="Administración de blogs" />
                </Item>
                <QuarteryTitle>Administración de blogs</QuarteryTitle>
            </List>
        </Block>
    );
};

export default ListBlock;

