import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { SecondaryTitle } from "../UI/Titles";
import { BlockList, List } from "./BlockList";
import { white } from "../UI/Variables";

const FooterContactContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    align-self: stretch;
    color: ${white};
    @media screen and (min-width: 768px) {
        width: 33.33%;
    }
`;

const FooterContact = () => {
    return (
        // Pie de pagina del sitio web
        <FooterContactContainer>
            <SecondaryTitle>Contacto:</SecondaryTitle>
            <BlockList>
                <List>
                    <Link to="mailto:info@emenvimarketing.com" target="_blank">
                        ✉ info@emenvimarketing.com
                    </Link>
                </List>

                <List>
                    <Link to="https://wa.me/573209737557/" target="_blank">
                        ✆ (+57) 320 973 7557
                    </Link>
                </List>

                <List>
                    <Link to="https://tawk.to/chat/63e5323a47425128791270af/1gorkodas/" target="_blank">
                        🗪 Chat Web
                    </Link>
                </List>
            </BlockList>
        </FooterContactContainer>
    );
}

export default FooterContact;

