/* Variables de colores */

export const white = "#ffffff";
export const black = "#000000";
export const lightBlack = "#464646";
export const blue = "#2A7AE4";
export const lightBlue = "#EAF2FD";
export const navyBlue = "#001f3f";
export const gray = "#A2A2A2";
export const darkGray = "#333";
export const lightGrey = "#F5F5F5";
export const paleTurquoise = "#76b5c5";

