import React from 'react';

const ContactForm = () => {
    return (
        <div style={{ height: '75vh', width: '100%' }}>
            <iframe
                src="https://share.hsforms.com/14qPmaW3EQli9uWupl-XVtgrk362"  // Reemplaza con la URL del sitio que quieres incrustar
                style={{ border: 'none', height: '100%', width: '100%' }}
                title="External Site"
            />
        </div>
    );
};

export default ContactForm;

