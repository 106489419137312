import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const BlockCards = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    align-self: stretch;
    flex-wrap: wrap;
    @media screen and (min-width: 768px) {
        flex-direction: row;
        gap: 2rem;
    }
`;

const Card = styled.img`
    width: 100%;
    @media screen and (min-width: 768px) {
        width: 12rem;
    }
    @media screen and (min-width: 1024px) {
        width: 32rem;
    }
`;

const Cards = () => {
    return (
        <BlockCards>
            <Link to="https://edme-geek.vercel.app/" target='_blank'>
                <Card src="https://i.imgur.com/NQfpenH.jpg" alt="EdmeGeek" title="EdmeGeek" />
            </Link>

            <Link to="https://cifratext.vercel.app/" target='_blank'>
                <Card src="https://i.imgur.com/zrfSQeS.png" alt="CifraText" title="CifraText" />
            </Link>

            <Link to="https://edmemovies.netlify.app/" target='_blank'>
                <Card src="https://i.imgur.com/Ie7sAam.png" alt="Edme Movies" title="Edme Movies" />
            </Link>
        </BlockCards>
    );
};

export default Cards;

