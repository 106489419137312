import styled from "styled-components";
import { lightGrey } from "./Variables";

export const MainParagraph = styled.p`
    color: ${lightGrey};
    font-family: "Raleway", sans-serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    @media (min-width: 768px) {
        font-size: 1rem;
    }
`;

export const SecondaryParagraph = styled.p`
    font-family: "Raleway", sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;

export const BannerParagraph = styled.p`
    font-family: "Raleway", sans-serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    @media (min-width: 768px) {
        font-size: 1rem;
    }
    @media (min-width: 1024px) {
        font-size: 1.375rem;
    }
`;

