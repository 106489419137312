import React from 'react';
import styled from 'styled-components';
import { SecondaryTitle } from "../../UI/Titles.jsx";
import { lightBlack, lightBlue } from "../../UI/Variables.js";
import Cards from "./Cards.jsx";

const Section = styled.section`
    width: 100%;
    height: auto;
    background: ${lightBlue};
    color: ${lightBlack};
`;

const Container = styled.div`
    width: auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    @media screen and (min-width: 768px) {
        max-width: 71rem;
        margin: auto;
        padding: 2rem;
    }
    @media screen and (min-width: 1024px) {
        padding: 4rem 2rem;
    }
`;

const PortfolioSection = () => {
    return (
        <Section>
            <Container>
                <SecondaryTitle>Portafolio</SecondaryTitle>
                <Cards />
            </Container>
        </Section>
    );
};

export default PortfolioSection;

