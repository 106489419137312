import React from "react";
import CallToAction from "../../components/CallToAction";
import AboutUsSection from "../../components/Sections/AboutUs";
import ServicesSection from "../../components/Sections/Services";
import ContactSection from "../../components/Sections/Contact";

const Home = () => {
    return (
        // Portada del sitio web
        <div>
            <CallToAction
                title="¡Impulsa tu marca en medios digitales hoy!"
                description="Emenvi Marketing ofrece soluciones de marketing digital innovadoras y personalizadas, enfocándose en desarrollo web, diseño de piezas publicitarias, gestión de redes sociales y publicidad digital."
                image="https://i.imgur.com/bmtAfqY.jpg"
            />
            <AboutUsSection />
            <ServicesSection />
            <ContactSection />
        </div>
    );
}

export default Home;
