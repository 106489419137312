import React from 'react';
import PortfolioSection from '../../components/Sections/Portfolio';
import CallToAction from '../../components/CallToAction';

const Portfolio = () => {
    return (
        <div>
            <CallToAction
                title="¡Nuestros Proyectos!"
                description="Nos enfocamos en entender las necesidades únicas de cada cliente para diseñar campañas que no solo aumenten su visibilidad en línea, sino que también impulsen el crecimiento y la lealtad de su audiencia."
                image="https://i.imgur.com/woDBLFj.jpg"
            />
            <PortfolioSection />
        </div>
    );
};

export default Portfolio;

