import React from 'react';
import styled from 'styled-components';
import { SecondaryTitle } from "../../UI/Titles.jsx";
import { SecondaryParagraph } from "../../UI/Paragraphs.jsx";
import { lightBlack, lightBlue } from "../../UI/Variables.js";
import ContactForm from './ContactForm.jsx';

const Section = styled.section`
    width: 100%;
    height: auto;
    background: ${lightBlue};
    color: ${lightBlack};
`;

const Container = styled.div`
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.625rem;
    align-self: stretch;
    padding: 1rem;
    @media screen and (min-width: 768px) {
        max-width: 71rem;
        margin: auto;
        padding: 2rem;
    }
    @media screen and (min-width: 1024px) {
        padding: 4rem 2rem;
        flex-direction: row-reverse;
        gap: 1rem;
    }
`;

const RightContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
    flex: 1 0 0;
`;

const ContactInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    align-self: stretch;
`;

const FormContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
    align-self: stretch;
`;

const LeftContainer = styled.div`
    display: none;
    @media screen and (min-width: 1024px) {
        width: 29rem;
        height: 38rem;
        display: block;
        flex-shrink: 0;
        background: url(https://i.imgur.com/b4zecla.png) lightgray 50% / cover no-repeat;
    }
`;

const ContactSection = () => {
    return (
        <Section>
            <Container>
                <RightContainer>
                    <ContactInfo>
                        <SecondaryTitle>Contacto</SecondaryTitle>
                        <SecondaryParagraph>¿Quieres contactarme?</SecondaryParagraph>
                        <SecondaryParagraph>Complete el siguiente formulario y nos pondremos en contacto con usted lo antes posible.</SecondaryParagraph>
                    </ContactInfo>
                    <FormContainer>
                        <ContactForm />
                    </FormContainer>
                </RightContainer>
                <LeftContainer />
            </Container>
        </Section>
    );
};

export default ContactSection;

