import React from 'react';
import styled from 'styled-components';
import { MainTitle } from "../UI/Titles.jsx";
import { BannerParagraph } from "../UI/Paragraphs.jsx";
import { navyBlue, white, lightBlue } from "../UI/Variables";
import ImageCallToAction from "./ImageCallToAction.jsx";
import SocialIcons from "./SocialIcons.jsx";

const Section = styled.section`
    width: 100%;
    height: auto;
    background: ${navyBlue};
    color: ${white};
    border-top: 2px solid ${lightBlue};
`;

const Container = styled.div`
    width: auto;
    max-width: 71rem;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 1rem;
    align-self: stretch;
    padding: 2rem 1rem;
    @media screen and (min-width: 768px) {
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
        gap: 2rem;
        padding: 2rem;
    }
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    align-self: stretch;
    @media screen and (min-width: 768px) {
        width: 100%;
        gap: 1rem;
        justify-content: center;
    }
`;

const CallToAction = ({ title, description, image }) => {
    return (
        <Section>
            <Container>
                <ImageCallToAction image={image} />
                <Content>
                    <MainTitle>{title}</MainTitle>
                    <BannerParagraph>{description}</BannerParagraph>
                    <SocialIcons />
                </Content>
            </Container>
        </Section>
    );
};

export default CallToAction;

