import React from 'react';
import styled from 'styled-components';
import { SecondaryTitle } from "../../UI/Titles.jsx";
import { SecondaryParagraph } from "../../UI/Paragraphs.jsx";
import { lightBlack, lightBlue } from "../../UI/Variables.js";


const Section = styled.section`
    width: 100%;
    height: auto;
    background: ${lightBlue};
    color: ${lightBlack};
`;

const Container = styled.div`
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    padding: 1rem;
    @media screen and (min-width: 768px) {
        max-width: 71rem;
        margin: auto;
        padding: 2rem;
    }
    @media screen and (min-width: 1024px) {
        padding: 4rem 2rem;
    }
`;

const AboutUsSection = () => {
    return (
        <div>
            <Section>
                <Container>
                    <SecondaryTitle>Quienes Somos</SecondaryTitle>
                    <SecondaryParagraph>Emenvi Marketing nació con una visión clara: transformar la manera en que las empresas se conectan con sus clientes en el mundo digital. Desde nuestros inicios, hemos trabajado con pasión y dedicación para ofrecer estrategias de marketing digital innovadoras y efectivas que generan resultados medibles.</SecondaryParagraph>
                    <SecondaryParagraph>Nuestra misión es empoderar a las empresas a través de soluciones de marketing digital personalizadas y creativas. Nos enfocamos en entender las necesidades únicas de cada cliente para diseñar campañas que no solo aumenten su visibilidad en línea, sino que también impulsen el crecimiento y la lealtad de su audiencia.</SecondaryParagraph>
                    <SecondaryParagraph>Emenvi Marketing ofrece soluciones de marketing digital innovadoras y personalizadas, enfocándose en desarrollo web, diseño de piezas publicitarias, gestión de redes sociales y publicidad digital. Con un servicio dedicado y valores de transparencia, innovación y resultados, ayudando a las empresas a crecer y conectar con sus clientes en el mundo digital.</SecondaryParagraph>
                    <SecondaryParagraph>En Emenvi Marketing, creemos en construir relaciones a largo plazo con nuestros clientes. Si estás buscando un socio de confianza para llevar tu marketing digital al siguiente nivel, estamos aquí para ayudarte. Contáctanos hoy mismo y descubre cómo podemos impulsar tu negocio en el mundo digital.</SecondaryParagraph>
                </Container>
            </Section>
        </div>
    );
};

export default AboutUsSection;

