import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import Services from "./pages/Services";
import Contact from "./pages/Contact";
import Portfolio from "./pages/Portfolio";
import Footer from "./components/Footer";
import Page404 from "./pages/Page404";

function App() {
    return (
        <Router>
            <Header />
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/quienes-somos' element={<AboutUs />} />
                <Route path='/servicios' element={<Services />} />
                <Route path='/contacto' element={<Contact />} />
                <Route path='/portafolio' element={<Portfolio />} />
                <Route path='/*' element={<Page404 />} />
            </Routes>
            <Footer />
        </Router>
    );
}

export default App;
