import React from "react";
import styled from "styled-components";
import Logo from "./Logo.jsx";
import Navegation from "./Navegation.jsx";
import MobileIcon from "./MobileIcon.jsx";
import { navyBlue } from "../UI/Variables.js";

const Head = styled.header`
    width: 100%; /* Ancho del header */
    height: auto;
    background-color: ${navyBlue}; /* Color de fondo del header */
`;

const HeaderContainer = styled.div`
    width: auto;
    max-width: 1136px;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    @media screen and (min-width: 768px) {
        padding: 1rem 2rem;
    }
    @media screen and (min-width: 1024px) {
        padding: 2rem;
    }
`;

const Header = () => {
    return (
        // Encabezado del sitio web
        <Head>
            <HeaderContainer>
                <Logo />
                <Navegation />
                <MobileIcon />
            </HeaderContainer>
        </Head>
    );
}

export default Header;

