import React from "react";
import styled from "styled-components";
import { SecondaryParagraph } from "../UI/Paragraphs";
import { white, lightGrey } from "../UI/Variables";

const CopyrightContainer = styled.div`
    width: 100%;
    padding: 1.25rem 0;
    border-top: 2px solid ${lightGrey};
    text-align: center;
    color: ${white};
    @media screen and (min-width: 1024px) {
        padding: 2rem 0;
    }
`;

const Copyright = () => {
    return (
        // Pie de pagina del sitio web
        <CopyrightContainer>
            <SecondaryParagraph>Desarrollado por Eduardo Mendez 2024</SecondaryParagraph>
        </CopyrightContainer>
    );
}

export default Copyright;
