import React from 'react';
import CallToAction from "../../components/CallToAction";
import ContactSection from '../../components/Sections/Contact';

const Contact = () => {
    return (
        <div>
            <CallToAction
                title="¡Contáctenos!"
                description="En Emenvi Marketing, creemos en construir relaciones a largo plazo con nuestros clientes. Si estás buscando un socio de confianza para llevar tu marketing digital al siguiente nivel, estamos aquí para ayudarte. Contáctanos hoy mismo y descubre cómo podemos impulsar tu negocio en el mundo digital."
                image="https://i.imgur.com/5nCoD6k.jpg"
            />
            <ContactSection />
        </div>
    );
};

export default Contact;

