import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { BlockList, List } from "./BlockList";
import { SecondaryTitle } from "../UI/Titles";
import { white } from "../UI/Variables";

const FooterLinksContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    align-self: stretch;
    color: ${white};
    @media screen and (min-width: 768px) {
        width: 33.33%;
    }
`;

const FooterLinks = () => {
    return (
        // Pie de pagina del sitio web
        <FooterLinksContainer>
            <SecondaryTitle>Enlaces de Interes:</SecondaryTitle>
            <BlockList>
                <List>
                    <Link to="/quienes-somos">Sobre Nosotros</Link>
                </List>

                <List>
                    <Link to="/servicios">Servicios</Link>
                </List>

                <List>
                    <Link to="/portafolio">Portafolio</Link>
                </List>

                <List>
                    <Link to="https://emenvimarketing.blogspot.com/" target="_blank">Blog</Link>
                </List>

                <List>
                    <Link to="/contacto">Contacto</Link>
                </List>
            </BlockList>
        </FooterLinksContainer>
    );
}

export default FooterLinks;

