import styled from "styled-components";
import { white, blue } from "../UI/Variables";

export const BlockList = styled.ul`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;

export const List = styled.li`
    color: ${white};
    font-family: "Raleway", sans-serif;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5;
    &:hover {
        color: ${blue};
    }
`;

