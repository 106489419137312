import React from 'react';
import CallToAction from "../../components/CallToAction";
import AboutUsSection from '../../components/Sections/AboutUs';

const AboutUs = () => {
    return (
        <div>
            <CallToAction
                title="¡Acerca de Nosotros!"
                description="Nuestra misión es empoderar a las empresas a través de soluciones de marketing digital personalizadas y creativas. Nos enfocamos en entender las necesidades únicas de cada cliente para diseñar campañas que no solo aumenten su visibilidad en línea, sino que también impulsen el crecimiento y la lealtad de su audiencia."
                image="https://i.imgur.com/2a8TDfj.jpg"
            />
            <AboutUsSection />
        </div>
    );
};

export default AboutUs;

