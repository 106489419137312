import React from 'react';
import ServicesSection from '../../components/Sections/Services'
import CallToAction from '../../components/CallToAction';

const Services = () => {
    return (
        <div>
            <CallToAction
                title="¡Nuestros Servicios!"
                description="Emenvi Marketing ofrece soluciones de marketing digital innovadoras y personalizadas, enfocándose en desarrollo web, diseño de piezas publicitarias, gestión de redes sociales y publicidad digital."
                image="https://i.imgur.com/HjFTPxG.jpg"
            />
            <ServicesSection />
        </div>
    );
};

export default Services;

