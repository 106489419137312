import styled from "styled-components";
import { white, lightBlack } from "./Variables";

export const MainTitle = styled.h1`
    color: ${white};
    font-family: "Raleway", sans-serif;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.3;
    @media (min-width: 768px) {
        font-size: 3.25rem;
    }
`;

export const SecondaryTitle = styled.h1`
    font-family: "Raleway", sans-serif;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.3;
    @media (min-width: 768px) {
        font-size: 2rem;
    }
`;

export const TertiaryTitle = styled.h1`
    color: ${lightBlack};
    font-family: "Raleway", sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.3;
    @media (min-width: 768px) {
        font-size: 1.375rem;
    }
`;

export const QuarteryTitle = styled.h1`
    color: ${lightBlack};
    font-family: "Raleway", sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.3;
`;

