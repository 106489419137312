import React from 'react';
import styled from 'styled-components';
import { SecondaryTitle } from "../../components/UI/Titles.jsx";
import { SecondaryParagraph } from "../../components/UI/Paragraphs.jsx";
import { lightBlack, lightBlue, navyBlue } from "../../components/UI/Variables.js";
import { Link } from 'react-router-dom';

const Section = styled.section`
    width: 100%;
    height: auto;
    background: ${lightBlue};
    color: ${lightBlack};
`;

const Container = styled.div`
    width: auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    @media screen and (min-width: 768px) {
        max-width: 71rem;
        margin: auto;
        padding: 2rem;
    }
    @media screen and (min-width: 1024px) {
        padding: 4rem 2rem;
    }
`;

const Button = styled.button`
    display: flex;
    padding: 0.75rem 1rem;
    justify-content: center;
    align-items: center;
    background: ${navyBlue};
    border: 2px solid ${navyBlue};
    color: ${lightBlue};
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
    &:hover {
        background: ${lightBlue};
        color: ${navyBlue};
        border: 2px solid ${navyBlue};
    }
`;

const Page404 = () => {
    return (
        <Section>
            <Container>
                <SecondaryTitle>Error 404</SecondaryTitle>
                <SecondaryParagraph>Página no encontrada, es posible el enlace introdujiste este mal escrito o se haya eliminado, haz clic en el siguiente botón para ir a la página principal.</SecondaryParagraph>
                <Link to="/">
                    <Button>Página Principal</Button>
                </Link>
            </Container>
        </Section>
    );
};

export default Page404;

