import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const SocialBlock = styled.ul`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    gap: 0.5rem;
    @media screen and (min-width: 768px) {
        gap: 2rem;
    }
`;

const List = styled.li`
    text-decoration: none;
    color: inherit;
`;

const IconImage = styled.img`
    width: 100%;
    height: auto;
`;

const SocialIcons = () => {
    return (
        <SocialBlock>
            <List>
                <Link to="https://www.linkedin.com/in/emenvimarketing/" target="_blank">
                    <IconImage src="https://img.icons8.com/ios-filled/32/ffffff/linkedin.png" alt="Icono de Linkedin" />
                </Link>
            </List>

            <List>
                <Link to="https://facebook.com/emenvimarketing/" target="_blank">
                    <IconImage src="https://img.icons8.com/material/32/ffffff/facebook-new.png" alt="Icono de Facebook" />
                </Link>
            </List>

            <List>
                <Link to="https://www.instagram.com/emenvimarketing/" target="_blank">
                    <IconImage src="https://img.icons8.com/windows/32/ffffff/instagram-new.png" alt="Icono de Instagram" />
                </Link>
            </List>

            <List>
                <Link to="https://wa.me/573209737557" target="_blank">
                    <IconImage src="https://img.icons8.com/ios-filled/32/ffffff/whatsapp--v1.png" alt="Icono de whatsapp" />
                </Link>
            </List>
        </SocialBlock>
    );
};

export default SocialIcons;

