import React from "react";
import styled from "styled-components";
import FooterInfo from "./FooterInfo.jsx";
import FooterLinks from "./FooterLinks.jsx";
import FooterContact from "./FooterContact.jsx";
import Copyright from "./Copyright.jsx";
import { darkGray, white } from "../UI/Variables.js";

const FooterSection = styled.footer`
    width: 100%;
    height: auto;
    background: ${darkGray}; /* Color de fondo de la seccion llamada a la acción */
    color: ${white};
`;

const FooterContainer = styled.div`
    width: 100%;
    max-width: 1136px;
    margin: auto;
    display: flex;
    flex-direction: column;
    padding: 0 2rem;
    justify-content: center;
    gap: 2rem;
    align-items: center;
    @media screen and (min-width: 768px) {
        gap: 4rem;
    }
    @media screen and (min-width: 1024px) {
        padding: 0 4rem;
    }
`;

const MainFooter = styled.div`
    width: 100%;
    display: flex;
    padding-top: 4rem;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    align-self: stretch;
    @media screen and (min-width: 768px) {
        flex-direction: row;
    }
    @media screen and (min-width: 1024px) {
        max-width: 58.75rem;
        margin: auto;
    }
`;

const Footer = () => {
    return (
        // Pie de pagina del sitio web
        <FooterSection>
            <FooterContainer>
                <MainFooter>
                    <FooterInfo />
                    <FooterLinks />
                    <FooterContact />
                </MainFooter>
                <Copyright />
            </FooterContainer>
        </FooterSection>
    );
}

export default Footer;

