import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    margin: auto;
`;

const ImageContainer = styled.img`
    width: 6.125rem;
    height: 6.125rem;
    border-radius: 98px;
    background-image: url(${props => props.image});
    background-color: lightgray;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    @media screen and (min-width: 768px) {
        width: 9.125rem;
        height: 9.125rem;
        border-radius: 146px;
    }
    @media screen and (min-width: 1024px) {
        width: 23rem;
        height: 23rem;
        border-radius: 368px;
        flex-shrink: 0;
    }
`;

const ImageCallToAction = ({ image }) => {
    return (
        <Container>
            <ImageContainer image={image} />
        </Container>
    );
};

export default ImageCallToAction;

