import React from "react";
import styled from "styled-components";
import { SecondaryTitle } from "../UI/Titles";
import { SecondaryParagraph } from "../UI/Paragraphs";
import { white } from "../UI/Variables";

const FooterInfoContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    align-self: stretch;
    color: ${white};
    @media screen and (min-width: 768px) {
        width: 33.33%;
    }
`;

const FooterInfo = () => {
    return (
        // Pie de pagina del sitio web
        <FooterInfoContainer>
            <SecondaryTitle>Eduardo Mendez Digital</SecondaryTitle>
            <SecondaryParagraph>Desarrollador de páginas web a la medida, me inspiro a crear sitios web dinámicos y creativos, adaptables a cualquier dispositivo. Con mi servicio tu negocio o emprendimiento tendrá presencia digital para que tus productos o servicios se vean en internet.</SecondaryParagraph>
        </FooterInfoContainer>
    );
}

export default FooterInfo;

