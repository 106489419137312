import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { white, paleTurquoise } from "../UI/Variables.js";

const LogoContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.75rem;
`;

const LogoImg = styled.div`
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 98px;
    background-image: url("https://i.imgur.com/sjlvgBJ.jpg");
    background-color: lightgray;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    @media screen and (min-width: 768px) {
        width: 4.125rem;
        height: 4.125rem;
    }
    @media screen and (min-width: 1024px) {
        width: 6.125rem;
        height: 6.125rem;
    }
`;

const Title = styled.h1`
    font-family: 'raleway', sans-serif;
    color: ${white};
    text-align: center;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.5;
    &:hover {
        color: ${paleTurquoise};
    }
    @media screen and (min-width: 768px) {
        font-size: 1rem;
    }
    @media screen and (min-width: 1024px) {
        font-weight: 700;
    }
`;

const Logo = () => {
    return (
        // Encabezado del sitio web
        <LogoContainer>
            <Link to="/">
                <LogoImg />
            </Link>
            <Link to="/">
                <Title>Emenvi Marketing</Title>
            </Link>
        </LogoContainer>
    );
}

export default Logo;

