import React, { useState } from "react";
import styled from "styled-components";
import Navegation from "./Navegation.jsx";
import image from "../../assets/img/iconos/bars-solid.svg";
import { white } from "../UI/Variables.js";

const MobileIconContainer = styled.div`
    display: block;
    @media screen and (min-width: 1024px) {
        display: none;
    }
`;

const MobileIcon = () => {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <MobileIconContainer onClick={toggleMenu}>
            <img src={image} color={white} width="32px" />
            <Navegation isOpen={menuOpen} />
        </MobileIconContainer>
    );
}

export default MobileIcon;

