import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { white, paleTurquoise } from "../UI/Variables.js";

const Nav = styled.nav`
    display: ${props => (props.isOpen ? "block" : "none")}; /* Mostrar u ocultar el menú según el valor de la prop isOpen */
    @media screen and (min-width: 1024px) {
        display: block;
    }
`;

const BlockList = styled.ul`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    @media screen and (min-width: 1024px) {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 2rem;
    }
`;

const List = styled.li`
    font-family: 'raleway', sans-serif;
    color: ${white};
    text-align: center;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.5;
    &:hover {
        color: ${paleTurquoise};
    }
    @media screen and (min-width: 768px) {
        font-size: 1rem;
    }
    @media screen and (min-width: 1024px) {
        font-weight: 700;
    }
`;

const Navegation = ({ isOpen }) => {
    return (
        <Nav isOpen={isOpen}>
            <BlockList>
                <List>
                    <Link to="/">Home</Link>
                </List>

                <List>
                    <Link to="/quienes-somos">Quienes Somos</Link>
                </List>

                <List>
                    <Link to="/servicios">Servicios</Link>
                </List>

                <List>
                    <Link to="/portafolio">Portafolio</Link>
                </List>

                <List>
                    <Link to="/contacto">Contacto</Link>
                </List>
            </BlockList>
        </Nav>
    );
}

export default Navegation;

